class Calculations {

    numbers = (queryInfo, parcelInfo, zoneInfo, neighborhoodInfo, realtyMoleInfo, unitMix) => {

        var maisonetteSize = 360;
        var maisonSize = 220;
        var averageUnitSize = 290;
        var roomType = "50/50 - Studios/Coliving";

        var rentAvgFuture = Math.floor(parseFloat(realtyMoleInfo.maisonette.rent * 1.1025 * 1));

        if (unitMix === 0) {
            averageUnitSize = 360;
            roomType = "All Studios rooms";
            rentAvgFuture = Math.floor(parseFloat(realtyMoleInfo.maisonette.rent * 1.1025 * 1.1));
        }
        else if (unitMix === 25) {
            averageUnitSize = (maisonSize * .25) + (maisonetteSize * .75);
            roomType = "75/25 - Studios/Coliving";
            rentAvgFuture = Math.floor(parseFloat(realtyMoleInfo.maisonette.rent * 1.1025 * .9));
        }
        else if (unitMix === 75) {
            averageUnitSize = (maisonSize * .75) + (maisonetteSize * .25);
            roomType = "25/75 - Studios/Coliving";
            rentAvgFuture = Math.floor(parseFloat(realtyMoleInfo.maisonette.rent * 1.1025 * 0.75));
        }
        else if (unitMix === 100) {
            averageUnitSize = 220;
            roomType = "All Coliving rooms";
            rentAvgFuture = Math.floor(parseFloat(realtyMoleInfo.maisonette.rent * 1.1025 * 0.6));
        }

        const numberRooms = Math.floor(parseFloat(parcelInfo.building_size) / averageUnitSize);

        const revenue = numberRooms * rentAvgFuture * 12;
        const noi = revenue * 0.70;

        const calculatedValues = { rentAverage: rentAvgFuture, numberRooms: numberRooms, revenue: revenue, noi: noi, roomType: roomType };
        return calculatedValues;
    }

}

export default new Calculations();