import React, { Component } from 'react'

import './home.css'

import { Grid, Button } from '@mui/material'

import backgroundImage from '../../files/images/tour.jpg'

class VirtualTour extends Component {

    render() {

        return (
            <div className="row half">
                <Grid container spacing={4}>
                <Grid item xs={12} md={6} order={{ xs: 2 }}>
                    <img className="feature-image" src={backgroundImage} alt="test" />
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 1 }}>
                    <h1 className="feature-title">Virtual Tour</h1>
                    <p className="feature-body">
                        Click through the image below to take a virtual tour of one of our properties.
                    </p>
                    <a className="nodecoration" href="https://renderings.madelongroup.com/madelon-jefferson-2/" target="_blank" rel="noreferrer"><Button color='secondary' variant='outlined' size="large">View Tour</Button></a>
                </Grid>
                </Grid>
            </div>
        )
    }
}

export default VirtualTour